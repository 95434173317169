@import url("https://fonts.googleapis.com/css2?family=Bagel+Fat+One&display=swap");

* {
  font-family: "Bagel Fat One", sans-serif;
}

body {
  background: #d6ecff;
  color: #381f31;
}

#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
}
@media (min-width: 1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}
#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

.App {
  overflow-x: hidden;
}

.ca {
  word-break: break-word;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s linear;
}

a:hover,
button:hover {
  opacity: 0.7;
}

@keyframes rotate45 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(30deg);
  }
}

.logo-animation {
  animation: rotate45 0.5s ease-in-out infinite alternate;
}

.text-title {
  background: var(--Linear, linear-gradient(90deg, #ffd87e 0%, #ffba00 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000;
}

.bg-primary {
  background: linear-gradient(90deg, #fcc74d 0%, #fdcd4b 100%);
}
